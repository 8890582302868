export const PATH_LIST = {
  es: {
    home: "",
    blog: "/es/blog",
    opinions: "/es/opiniones",
    about: "/comparador-renting-coches",
    stock: "/renting",
    brand: `/renting-[brand]`,
    model: `/renting-[brand]/[model]`,
    version: `/renting-[brand]/[model]/[version]`,
    commercial: "/renting-furgonetas",
    "pre-owned": "/renting-seminuevos",
    faq: "/preguntas-frecuentes",
    contact: "/contacto",
    employee: "/renting-particulares",
    freelance: "/renting-autonomos",
    flexible_stock: "/compra-flexible",
    flexible: "/compra-flexible",
    flexible_brand: `/compra-flexible/[brand]`,
    flexible_model: `/compra-flexible/[brand]/[model]`,
    flexible_version: `/compra-flexible/[brand]/[model]/[version]`,
    company: "/renting-empresas",
    multistep: "/multistep",
    "brand-landing": "/landing/[brand]",
    "brand-multistep": "/landing/[brand]/multistep",
    online: "/online/[id]",
    cookies: "/politica-cookies",
    terms: "/terminos-condiciones",
    privacy: "/politica-privacidad",
    fca_terms: "/terminos-fca",
    vassla: "/vassla",
    quota: "/calculadora-cuota/[id]",
    "bip-drive": "/bip-drive",
    legal: "/aviso-legal"
  },
  pt: {
    home: "",
    blog: "/pt/blog",
    opinions: "/pt/opiniones",
    about: "/comparador-renting-carros",
    stock: "/renting",
    brand: `/renting-[brand]`,
    model: `/renting-[brand]/[model]`,
    version: `/renting-[brand]/[model]/[version]`,
    commercial: "/renting-carrinhas",
    "pre-owned": "/renting-seminovo",
    faq: "/perguntas-frequentes",
    contact: "/contacto",
    employee: "/renting-particulares",
    freelance: "/renting-independentes",
    company: "/renting-empresas",
    multistep: "/multistep",
    "brand-landing": "/landing/[brand]",
    "brand-multistep": "/landing/[brand]/multistep",
    online: "/online/[id]",
    cookies: "/uso-cookies",
    terms: "/termos-condicoes",
    privacy: "/politica-privacidade",
    fca_terms: "/terminos-fca"
  },
  it: {
    home: "",
    blog: "/it/blog",
    opinions: "/it/recensioni",
    about: "/comparatore-noleggio-auto",
    stock: "/noleggio-lungo-termine",
    brand: `/noleggio-lungo-termine-[brand]`,
    model: `/noleggio-lungo-termine-[brand]/[model]`,
    version: `/noleggio-lungo-termine-[brand]/[model]/[version]`,
    commercial: "/noleggio-lungo-termine-furgoni",
    "pre-owned": "/noleggio-lungo-termine-auto-usate",
    faq: "/domande-frequenti",
    contact: "/contatto",
    employee: "/noleggio-lungo-termine-privati",
    freelance: "/noleggio-lungo-termine-liberi-professionisti",
    company: "/noleggio-lungo-termine-aziende",
    multistep: "/multistep",
    "brand-landing": "/landing/[brand]",
    "brand-multistep": "/landing/[brand]/multistep",
    online: "/online/[id]",
    cookies: "/uso-cookies",
    terms: "/termini-condizioni",
    privacy: "/politica-privacy",
    fca_terms: "/terminos-fca"
  }
}

export const getPath = (locale, page, params = {}) => {
  let href = PATH_LIST[locale][page]
  Object.keys(params).forEach(key => {
    href = href.replace(`[${key}]`, params[key])
  })
  return href
}
